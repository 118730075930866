import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { withALErrorBoundary } from "../helpers/ErrorBoundary/ALErrorBoundary";
import { Link } from "./al_components/ALLink";
import { getCookie, isBrowser } from "../context/helpers";
import { useLocalizedSentenceDict } from "../hooks/useSentenceDict";
import breadcrumbData from "../context/breadcrumb.json";
import "./breadcrumb/styles.scss";

function Breadcrumb({
  productTitle,
  collectionTitle,
  pageTitle,
  levels = [],
  productType = null,
  productSubtype = null,
}) {
  const [levelBreadcrumb, updateLevelBreadcrumb] = useState([]);
  const [breadcrumbJsonLd, updateJsonLdBreadcrumb] = useState(null);
  const dict = useLocalizedSentenceDict();

  useEffect(() => {
    let _levelBreadcrumb = [];
    let _breadcrumbJsonLd = null;

    if (isBrowser) {
      _levelBreadcrumb = [
        {
          link: "/",
          name: "Home",
        },
      ];

      const pathArray = window.location.pathname.split("/").filter((e) => {
        if (e != null || e != "") {
          return e;
        }
      });
      if (levels && levels.length > 0) {
        levels.forEach((l) => {
          _levelBreadcrumb.push({
            link: l.link.link,
            name: l.title,
          });
        });
      } else {
        const idxCollection = pathArray.findIndex((e) => e == "collections");
        const idxProduct = pathArray.findIndex((e) => e == "products");
        const idxPage = pathArray.findIndex((e) => e == "pages");

        if (idxPage !== -1 && pageTitle) {
          _levelBreadcrumb.push({
            link: `/pages/${pathArray[idxPage + 1]}/`,
            name: pageTitle,
          });
        }
        // PDP
        if (idxProduct !== -1 && productTitle) {
          const plpUrl = getCookie("bread_url");
          const plpTitle = getCookie("bread_title");
          // Add breadcrumb only if
          if (plpUrl && plpTitle && !breadcrumbData.type[productType]) {
            _levelBreadcrumb.push({
              link: `${plpUrl}`,
              name: plpTitle,
            });
          }
          // Add type
          if (productType && breadcrumbData.type[productType]) {
            _levelBreadcrumb.push({
              link: breadcrumbData.type[productType],
              name: productType,
            });
          }
          // Add subtype
          if (productSubtype && breadcrumbData.subtype[productSubtype]) {
            _levelBreadcrumb.push({
              link: breadcrumbData.subtype[productSubtype].url,
              name: productSubtype,
            });
          }
          _levelBreadcrumb.push({
            link: `/products/${pathArray[idxProduct + 1]}/`,
            name: productTitle,
          });
        } else if (idxCollection !== -1 && collectionTitle) {
          // PLP
          // Add type from collection title (if available)
          if (breadcrumbData.subtype[collectionTitle]) {
            const _type = breadcrumbData.subtype[collectionTitle]?.type;
            _levelBreadcrumb.push({
              link: breadcrumbData.type[_type],
              name: _type,
            });
          }
          // Add current collection
          _levelBreadcrumb.push({
            link: `/collections/${pathArray[idxCollection + 1]}/`,
            name: collectionTitle,
          });
        }

        // Build JSON-LD
        if (_levelBreadcrumb.length > 0) {
          _breadcrumbJsonLd = {
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            description: "Breadcrumbs list",
            name: "Breadcrumbs",
            itemListElement: [],
          };
          _levelBreadcrumb.map((b, i) => {
            _breadcrumbJsonLd.itemListElement.push({
              "@type": "ListItem",
              position: i + 1,
              name: b.name,
              item: `https://www.analuisa.com/${b.link}/`,
            });
          });
        }
      }

      updateLevelBreadcrumb(_levelBreadcrumb);
      updateJsonLdBreadcrumb(_breadcrumbJsonLd);
    }
  }, []);

  if (levelBreadcrumb.length > 1) {
    return (
      <>
        {breadcrumbJsonLd ? (
          <Helmet>
            <script type="application/ld+json">{JSON.stringify(breadcrumbJsonLd)}</script>
          </Helmet>
        ) : null}
        <ol className="breadcrumb" itemScope itemType="https://schema.org/BreadcrumbList">
          {levelBreadcrumb.map((e, i) => (
            <li
              key={`breadcrumb_item_${i}`}
              className="breadcrumb_item"
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
            >
              {i !== 0 && <span className="breadcrumb_separator">/</span>}
              <meta itemProp="position" content={i + 1} />
              <Link className="breadcrumb_link" aria-label={e.name} itemProp="item" to={e.link}>
                <span itemProp="name">{dict.get(e.name)}</span>
              </Link>
            </li>
          ))}
        </ol>
      </>
    );
  }
  return <>&nbsp;</>;
}

export default withALErrorBoundary({
  name: "Breadcrumb",
  priority: "P2",
})(Breadcrumb);
