import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import RichTextContentful from "../../components/RichTextContentful";
import SEO from "../../components/seo";
import Breadcrumb from "../../components/Breadcrumb";

const pageStyles = {
  page_content: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "1240px",
    margin: "0 auto 50px auto",
    padding: "0px 20px",
  },
  page_content_title: {
    display: "flex",
  },
};

function Page({ path, pageContext }) {
  const [data, updateData] = useState(null);

  useEffect(() => {
    if (pageContext) {
      updateData(pageContext.data[0].node);
    }
  }, []);
  return data ? (
    <div style={pageStyles.page_content}>
      <SEO
        title={"title" in data ? data.title : data.handle}
        metaDescription={
          "seoContent" in data &&
          data.seoContent &&
          "description" in data.seoContent &&
          data.seoContent.description &&
          "description" in data.seoContent.description &&
          data.seoContent.description.description
            ? data.seoContent.description.description
            : ""
        }
      />
      <Helmet>
        <link rel="canonical" href={`https://www.analuisa.com${path}`} />
        <meta property="og:url" content={`https://www.analuisa.com${path}`} />
      </Helmet>
      {"title" in data ? <Breadcrumb pageTitle={data.title} /> : null}
      <h1 className="al_h1" style={pageStyles.page_content_title}>
        {"title" in data ? data.title : null}
      </h1>
      <RichTextContentful
        input={data?.content?.raw}
        references={data?.content?.references}
        prefixClassName="page"
      />
    </div>
  ) : // navigate("/404")
  null;
}

export default withALErrorBoundary({
  name: "Page",
  priority: "P3",
})(Page);
